import React, { Component } from 'react';
import {  BrowserRouter as Router, Route } from "react-router-dom";
import {Container,Row,Col,Table, Spinner} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import firebase from 'firebase/app';
import 'firebase/database';

class OrderItem extends Component{
	render(){
		let {invoiceId, time, status, total, date, userId} = this.props;
		return (
			<tr>
				<td>
					<LinkContainer to={"/invoice/"+invoiceId + "/" + userId }>
						<span className="btn btn-secondary">{invoiceId}</span>
					</LinkContainer>
				</td>
				<td>{date}
				</td>
				<td>{total}
				</td>
				<td>{status}
				</td>
			</tr>
		);
	}
}

export default class Orders extends Component{
	state = {
		orders: [],
		loading: false
	}
	async componentDidMount(){
		console.log('pulling relevant orders');
		this.setState({processing: true});
		let invoiceRef = await firebase.database().ref('invoices').once('value')
		
		let invoiceUsers = [];
		let invoices = [];
		invoiceRef.forEach((invUser)=>{
		//	console.log('user',invUser.key);
			invUser.forEach((inv)=>{
				invoices.push(inv.val())
			})
		})

		invoices.sort((a,b) => {return b.time - a.time})
	//	console.log('invoices',invoices);
		this.setState({orders: invoices,processing: false})
	}
	render(){
		let {orders,processing} = this.state;
		return (
			<Col>
				<h5>Orders</h5>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
						<th>#</th>
						<th>Date</th>
						<th>Amount</th>
						<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((o,i)=>{
							return <OrderItem {...o} key={'oi'+i} />
						})}
					</tbody>
				</Table>
				{processing && ( <Spinner /> )}
			</Col>
		);
	}
}