import React from 'react';

import {Container, Row, Col, Table, Button, Image} from 'react-bootstrap';


import firebase from 'firebase/app';
import 'firebase/database';

class Invoice extends React.Component{
	state = {
		invoice: {
			items: []
		},
		materials: {},
		detail: {}
	}
	componentDidMount(){
		console.log('invoice component props', this.props);
		let {userId, invoiceId} = this.props;

		Promise.all([
			firebase.database().ref('materials').once('value')
				.then((materialsSnap) => {
					return this.setState({materials: materialsSnap.val() })
				}),
			
			firebase.database().ref('detail').once('value')
				.then((detailSnap) => {
					return this.setState({detail: detailSnap.val() })
				})
		])
		.then(()=>{
			firebase.database().ref('invoices').child(userId).child(invoiceId).once('value')
				.then((invSnap)=>{
					let invoice = invSnap.val();
					this.setState({invoice});
				})	
		})



	
	}
	render(){
		let {invoice,materials,detail} = this.state;
		console.log(materials);
		console.log(detail);
		return (
			<Container>
				<Row>
					<Col xs={6} sm={6} md={6} lg={6}>
					<h4> Ship to: </h4>
						<p>
							{invoice.name}<br />
							{invoice.address1} <br />
							{invoice.address2}<br />
							{invoice.city}, &nbsp;
							{invoice.state}&nbsp;
							{invoice.zip}<br />
							{invoice.email}
						</p>

					</Col>
					<Col xs={6} sm={6} md={6} lg={6}>
						<h4>Payment Info</h4>
						<p>
							<strong>{'Total: $' + invoice.total }</strong><br />
							<strong>Status: </strong>{invoice.status}<br />
							<strong>Charge Status: </strong>{invoice.chargeStatus}<br />
							<strong>ChargeId: </strong>{invoice.chargeId}<br />
							<Button variant="primary">Update Status</Button>
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<thead>
								<tr>
									<th></th>
									<th>Fabric</th>
									<th>Blanket</th>
								</tr>
							</thead>
							<tbody>
							{invoice.items.map((item,ind)=>{
								return (<tr>
									<td>{ind+1}</td>
									<td>
									{item.detail}
									<Image src={ ( detail[item.detail].imageThumb ? detail[item.detail].imageThumb : detail[item.detail].image )} thumbnail />
									</td>
									<td>
									{item.material}
									<Image src={ (materials[item.material].imageThumb ? materials[item.material].imageThumb : materials[item.material].image )} thumbnail />
									</td>
								</tr>);
							})}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		)
	}
}
export default Invoice;