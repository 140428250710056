import React, { Component } from 'react';
import {  BrowserRouter as Router, Route, useParams } from "react-router-dom";
import {Container,Row} from 'react-bootstrap';
import Header from './Header';

import Orders from '../components/Orders'
import Inventory from '../components/Inventory'
import Invoice from '../components/Invoice';

import firebase from 'firebase/app';
import 'firebase/auth';

class Login extends Component{
	state = {
		email: '',
		password: '',
		emailError: null,
		passwordError: null,
	};

	_login(){
		let {email, password} = this.state;
		firebase.auth().signInWithEmailAndPassword(email,password);
	}

	render(){
		return (
			<div className="row">
				<div className="col-sm-12">
				<h5>Login </h5>
					<form>
					  <div className="form-group">
					    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
					    <input type="email" className="form-control" id="exampleInputEmail1" onChange={(txt)=>{this.setState({email:txt.target.value})}} />
					  </div>
					  <div className="form-group">
					    <label htmlFor="exampleInputPassword1" className="bmd-label-floating">Password</label>
					    <input type="password" className="form-control" id="exampleInputPassword1" onChange={(txt)=>{this.setState({password:txt.target.value})}} />
					  </div>
					</form>
					<button type="button" className="btn btn-primary btn-raised" onClick={this._login.bind(this)}>Login</button>
				</div>
			</div>
		);
	}
}

function InvoiceRoute(){
	let {invoiceId,userId} = useParams();
	console.log('params', invoiceId);
	return (<Invoice invoiceId={invoiceId} userId={userId} />);
}

export default class AppContainer extends Component{
	constructor(props){
		super(props);
		this.state = {
			loggedIn: false
		}
	}
	componentDidMount(){
		let c = this;
		firebase.auth().onAuthStateChanged(function(user) {
		  if (user) {
		  	console.log('logged in as ', user);
		    // User is signed in.
		    var displayName = user.displayName;
		    var email = user.email;
		    var emailVerified = user.emailVerified;
		    var photoURL = user.photoURL;
		    var isAnonymous = user.isAnonymous;
		    var uid = user.uid;
		    var providerData = user.providerData;
		    // ...
		    c.setState({loggedIn: true});
		  } else {
		    // User is signed out.
		    // ...
		  }
		});
	}
	render(){
		let {loggedIn} = this.state;
		return (
			<Container>
				<Router>
					<Header loggedIn={loggedIn} />
					
					{ loggedIn && (
						<Row>
	          <Route 
	            path="/orders" 
	            render={()=>{
	              return (<Orders />);
	            }}
	          />

	          <Route 
	            path="/invoice/:invoiceId/:userId" 
	            render={()=>{
	              return (<InvoiceRoute />);
	            }}
	          />

	          <Route 
	            path="/inventory" 
	            render={()=>{
	              return (<Inventory />);
	            }}
	          />
	          </Row>
					)}


					{ !loggedIn && (
						<Login />
					)}
					
				</Router>
			</Container>
		);
	}
}