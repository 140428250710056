import React from 'react';
import AppContainer from './containers/AppContainer';

import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const fbConfig = {
  apiKey: "AIzaSyC7x6UxWVxDmRot0UVgy388oHAv2jDgUVI",
  authDomain: "planket-3c72d.firebaseapp.com",
  databaseURL: "https://planket-3c72d.firebaseio.com",
  projectId: "planket-3c72d",
  storageBucket: "planket-3c72d.appspot.com",
  messagingSenderId: "1073858950353"
};
firebase.initializeApp(fbConfig);

function App() {
  return (
    <div className="App">
      <AppContainer />
    </div>
  );
}

export default App;
