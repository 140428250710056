import React, { Component } from 'react';
import {Form, Button, Image, InputGroup, ButtonGroup} from 'react-bootstrap';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import {randFileName} from '../lib/randFileName';

import ReactSuperSelect from 'react-super-select';

import '../lib/react-super-select.css';

export default class EditFabric extends Component{
	state = {
		id: '',
		name: '',
		image: '',
		imageName: '',
		quantity: 0,
		tags: [],
		selectedTags: []
	}

	componentDidMount(){
		let {fabricId} = this.props;
		if(fabricId){
			firebase.database().ref('detail').child(fabricId).once('value')
				.then((dSnap)=>{
					let fObj = dSnap.val()
					fObj.id = dSnap.key;
					this.setState( {
						id: fObj.id, 
						name: fObj.name, 
						image: fObj.image, 
						imageName: fObj.imageName, 
						quantity: fObj.quantity
					} );
					
					if(fObj.tags){
						console.log('selected tags!', fObj.tags);
						this.setState({selectedTags: fObj.tags})
					}else{
						console.log('no selected tags', this.state.selectedTags);
						this.setState({selectedTags: []})
					}
				})

			firebase.database().ref('tags').once('value')
				.then((tagSnap)=>{
					let tags = tagSnap.val();
					console.log('pulled tags:', tags);
					let mappedTags = tags.map((t, i)=>{
						return {name: t.toUpperCase(), id: t }
					});
					console.log('mapped tags', mappedTags);
					this.setState({tags: mappedTags});
				})
		}
	}
	async save(){
		let {id,name,image,imageName,quantity,selectedTags} = this.state;
		console.log('saving', id);
		console.log('state:', this.state);
		if(!imageName){
			console.log('no imageName detected, create it');
			let mdata = await firebase.storage().refFromURL(image).getMetadata();
			imageName = mdata.name;
		}
		if(id && name && image && imageName){
			firebase.database().ref('detail').child(id).update({name,image,quantity,imageName})
				.then(()=>{
					if(selectedTags && (selectedTags.length > 0) ){
						return firebase.database().ref('detail').child(id).update({tags: selectedTags})
					}else{
						return true;
					}
				})
				.then(()=>{
					window.alert("Saved!");
					this.props.closeModal();
				})
		}
	}
	reduceQuantity(){
		let {quantity} = this.state;
		this.setState({quantity: (quantity)-1 })
	}

	increaseQuantity(){
		let {quantity} = this.state;
		this.setState({quantity: (quantity)+1 })
	}
	_uploadImage(e){
		let t = this;
		// console.log(idata);
		// return;
		// File or Blob named mountains.jpg
		var file =e.target.files[0];
		var storageRef = firebase.storage().ref('details');
		// Create the file metadata
		var metadata = {
		  contentType: 'image/jpeg'
		};

		let newName = randFileName();

		// Upload file and metadata to the object 'images/mountains.jpg'
		var uploadTask = storageRef.child(newName).put(file, metadata);

		// Listen for state changes, errors, and completion of the upload.
		uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
		  function(snapshot) {
		    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
		    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		    console.log('Upload is ' + progress + '% done');
		    switch (snapshot.state) {
		      case firebase.storage.TaskState.PAUSED: // or 'paused'
		        console.log('Upload is paused');
		        break;
		      case firebase.storage.TaskState.RUNNING: // or 'running'
		        console.log('Upload is running');
		        break;
		    }
		  }, function(error) {
		  	console.warn(error);
		  // A full list of error codes is available at
		  // https://firebase.google.com/docs/storage/web/handle-errors
		  switch (error.code) {
		    case 'storage/unauthorized':
		      // User doesn't have permission to access the object
		      
		      break;

		    case 'storage/canceled':
		      // User canceled the upload
		      break;

		   // ...

		    case 'storage/unknown':
		      // Unknown error occurred, inspect error.serverResponse
		      break;
		  }
		}, function(d) {
			console.log(d);
		  // Upload completed successfully, now we can get the download URL
		  uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
		    console.log('File available at', downloadURL);
		    t.setState({image: downloadURL, imageName: newName});
		  });
		});
	}
	_tagChange(d){
		console.log('tag changed',d);
		this.setState({selectedTags: d})
	}
	render(){
		let {fabricId} = this.props;
		let {id, name, image,quantity} = this.state;

		return (
			<Form>
				<InputGroup size="sm" className="mb-3">
					<InputGroup.Prepend>
						<InputGroup.Text>Quantity</InputGroup.Text>
					</InputGroup.Prepend>

				 	<ButtonGroup className="mr-2" aria-label="First group">
			      <Button variant="secondary" className="btn-sm" onClick={this.reduceQuantity.bind(this)}>-</Button>
			      <Button variant="secondary" className="btn-sm">{quantity}</Button>
			      <Button variant="secondary" className="btn-sm" onClick={this.increaseQuantity.bind(this)}>+</Button>
			    </ButtonGroup>
		    </InputGroup>

			  <Form.Group controlId="formBasicText">
			    <Form.Label>ID</Form.Label>
			    <Form.Control type="text" placeholder=".ie D202" disabled={(fabricId) ? true : false} 
			    	value={id} 
			    	onChange={(evt)=>{
			    		this.setState({id: evt.target.value});
			    	}} />
			    <Form.Text className="text-muted">
			      This id will be used to reference the fabric design.
			    </Form.Text>
			  </Form.Group>

			  <Form.Group controlId="formBasicText">
			    <Form.Label>Name</Form.Label>
			    <Form.Control type="text" placeholder="ie. Blue Flowers" value={name} onChange={(e) => {
			    	//console.log(e.target.value);
			    	let {value} = e.target;
			    	this.setState({name: value});
			    }}/>
			  </Form.Group>

			  <Form.Group controlId="formBasicText">
			  	<Form.Label>Image</Form.Label>
			    <Image thumbnail src={image} />
			    <input type="file" onChange={this._uploadImage.bind(this)} />
			  </Form.Group>

			  <Form.Group>
			  	<Form.Label>Tags</Form.Label>
			  	<ReactSuperSelect initialValue={this.state.selectedTags} multiple={true} keepOpenOnSelection={true} tags={true} dataSource={this.state.tags} onChange={this._tagChange.bind(this)} />
			  </Form.Group>
			  <Button variant="primary" type="button" onClick={this.save.bind(this)}>
			    Submit
			  </Button>
			</Form>
		);
	}
}