import React, { Component } from 'react';
import {  BrowserRouter as Router, Route } from "react-router-dom";
import {Container,Row,Col, Card,CardDeck,CardGroup,CardColumns,Button,Badge,Modal, Spinner} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import firebase from 'firebase/app';
import 'firebase/database';

import EditFabric from './EditFabric';
import EditMaterial from './EditMaterial';

class InventoryFabric extends Component{
	state = {
		detail: [],
		showFabricModal: false,
		fabric: {},
		fabricId: null
	}
	viewFabric(fab){
		console.log(fab);
		this.setState({fabricId: fab.id,showFabricModal: true, fabric: fab})
	}
	async componentDidMount(){
		console.log('pulling relevant details');
		this._pullData();
	}

	async _pullData(){
		let detailRef = await firebase.database().ref('detail').once('value')
		let details = [];
		detailRef.forEach((dt)=>{
			let detObj = dt.val();
			detObj.id = dt.key;
			details.push(detObj);
		})

		this.setState({detail: details })
	}

	render(){
		let { detail,showFabricModal,fabric,fabricId } = this.state;
		return (
				<Row>
					<Col sm={12} xs={12} md={12} lg={12}><Button variant="info" onClick={()=>this.setState({showFabricModal:true})}>ADD FABRIC</Button></Col>
					<Col style={{paddingTop:8,paddingBottom:24}}>
						<CardColumns>
							{detail.map((o,i)=>{
								return <InventoryItem {...o} key={'d'+i} viewItem={this.viewFabric.bind(this)} />
							})}
						</CardColumns>
					</Col>

		      <Modal show={showFabricModal} onHide={()=> this.setState({showFabricModal:false, fabricId: ''})}>
		        <Modal.Header closeButton>
		          <Modal.Title>Fabric</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<EditFabric fabricId={fabricId} {...fabric} closeModal={()=> {
		        		this.setState({showFabricModal: false, fabricId:''})
		        		this._pullData();
		        	}} />
		        </Modal.Body>
		        <Modal.Footer>
		          <Button variant="secondary" onClick={()=> this.setState({showFabricModal:false,fabricId: ''})}>
		            Close
		          </Button>
		        </Modal.Footer>
		      </Modal>

				</Row>
		);
	}
}

class InventoryMaterial extends Component{
	state = {
		materials: [],
		materialId: null,
		material: {}
	}
	async componentDidMount(){
		console.log('pulling relevant details');
		this._pullData();
	}

	async _pullData(){

		let materialsRef = await firebase.database().ref('materials').once('value')
		let materials = [];
		materialsRef.forEach((mt)=>{
			let mtObj = mt.val();
			mtObj.id = mt.key;
			materials.push(mtObj);
		})

		this.setState({materials: materials})
	}
	viewMaterial(mat){
		console.log(mat);
		this.setState({materialId: mat.id,showMaterialModal: true, material: mat})
	}
	render(){
		let {materials,showMaterialModal, materialId,material} = this.state;
		return (
			<Row>
				<Col sm={12} xs={12} md={12} lg={12}>
					<Button variant="info" onClick={()=>this.setState({showMaterialModal:true})}>ADD MATERIAL/PLANKET</Button>
				</Col>
				<Col style={{paddingTop:8,paddingBottom:24}}>
					<CardColumns>
						{materials.map((m,i)=>{
							return <InventoryItem {...m} key={'m'+i} viewItem={this.viewMaterial.bind(this)} />
						})}
					</CardColumns>
				</Col>

	      <Modal show={showMaterialModal} onHide={()=> this.setState({showMaterialModal:false,materialId:''})}>
	        <Modal.Header closeButton>
	          <Modal.Title>Blanket/Material</Modal.Title>
	        </Modal.Header>
	        <Modal.Body>
	        	<EditMaterial materialId={materialId} {...material}  closeModal={()=> {
	        		this.setState({showMaterialModal: false, fabricId:''})
	        		this._pullData();
	        	}} />
	        </Modal.Body>
	        <Modal.Footer>
	          <Button variant="secondary" onClick={()=> this.setState({showMaterialModal:false,materialId:''})}>
	            Close
	          </Button>
	        </Modal.Footer>
	      </Modal>
			</Row>
		);
	}
}

class InventoryItem extends Component{
	render(){
		let {image, name, quantity, id, imageThumb} = this.props;
		if(!imageThumb){
			imageThumb = image;
		}
		return (
			<Card className="p-4">
				<Card.Header>{id} <FontAwesomeIcon icon={faTrash} color="red" /></Card.Header>
			  <Card.Img variant="top" src={imageThumb} fluid="true" onClick={()=>{this.props.viewItem(this.props)}} />
			  <Card.Body>
			  	<a href="#" onClick={()=>{this.props.viewItem(this.props)}}>{name}</a>
			  </Card.Body>
			  <Card.Footer>
			    <Badge variant={(quantity > 0) ? 'dark' : 'danger'}>{quantity + ' LEFT'}</Badge>
			   </Card.Footer>
			</Card>
		);
	}
}

export default class Inventory extends Component{
	state = {
		detail: [],
		materials: [],
		showMaterialModal: false,
		materialId: null,
		material: {},
	}

	async componentDidMount(){
		console.log('pulling relevant details');
		this._pullData();
	}

	async _pullData(){
		let detailRef = await firebase.database().ref('detail').once('value')
		let details = [];
		detailRef.forEach((dt)=>{
			let detObj = dt.val();
			detObj.id = dt.key;
			details.push(detObj);
		})

		let materialsRef = await firebase.database().ref('materials').once('value')
		let materials = [];
		materialsRef.forEach((mt)=>{
			let mtObj = mt.val();
			mtObj.id = mt.key;
			materials.push(mtObj);
		})

		this.setState({detail: details, materials: materials})
	}

	render(){
		let { detail, materials, showFabricModal, showMaterialModal, materialId, fabricId} = this.state;
		let {material, fabric} = this.state;
		return (
			<Col sm={12} xs={12} md={12} lg={12}>
					<h5>Inventory</h5>
									
          <Route 
            path="/inventory/fabric" 
            render={()=>{
              return (<InventoryFabric />);
            }}
          />
				
          <Route 
            path="/inventory/material" 
            render={()=>{
              return (<InventoryMaterial />);
            }}
          />
      
			</Col>
		);
	}
}