import React, { Component } from 'react';
import {  BrowserRouter as Router, Route, Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import {Container,Row, Navbar, Nav, NavDropdown} from 'react-bootstrap';

import firebase from 'firebase/app';
import 'firebase/auth';
export default class Header extends Component{
	signOut(){
		firebase.auth().signOut();
	}
	render(){
		return (
			<Navbar bg="dark" variant="dark">
				<Navbar.Brand>
					PLANKET.SHOP ADMIN
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Nav className="mr-auto">

					<Nav.Item>
						<LinkContainer to="/orders">
						  <a className="nav-link">Orders</a>
						</LinkContainer>
					</Nav.Item>

					<NavDropdown title="Inventory" id="basic-nav-dropdown">
						<NavDropdown.Item>
							<Link to="/inventory/fabric">
						 	Fabric
							</Link>
						</NavDropdown.Item>
						<NavDropdown.Item>
							<Link to="/inventory/material">
						  	Blankets
							</Link>
						</NavDropdown.Item>
					</NavDropdown>

					{ this.props.loggedIn && (
					<LinkContainer to="/logout">
						<Nav.Link onClick={this.signOut.bind(this)}>
						  Logout
						</Nav.Link>
					</LinkContainer>
					)}
				</Nav>
			</Navbar>
		);
	}
}